exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktivere-teams-telefoni-index-tsx": () => import("./../../../src/pages/aktivere-teams-telefoni/index.tsx" /* webpackChunkName: "component---src-pages-aktivere-teams-telefoni-index-tsx" */),
  "component---src-pages-bygoneplus-tsx": () => import("./../../../src/pages/bygoneplus/[...].tsx" /* webpackChunkName: "component---src-pages-bygoneplus-tsx" */),
  "component---src-pages-case-trace-index-tsx": () => import("./../../../src/pages/case-trace/index.tsx" /* webpackChunkName: "component---src-pages-case-trace-index-tsx" */),
  "component---src-pages-dev-all-pages-tsx": () => import("./../../../src/pages/dev/all-pages.tsx" /* webpackChunkName: "component---src-pages-dev-all-pages-tsx" */),
  "component---src-pages-driftsinformation-apps-tsx": () => import("./../../../src/pages/driftsinformation/apps.tsx" /* webpackChunkName: "component---src-pages-driftsinformation-apps-tsx" */),
  "component---src-pages-driftsinformation-bredbaand-tsx": () => import("./../../../src/pages/driftsinformation/bredbaand.tsx" /* webpackChunkName: "component---src-pages-driftsinformation-bredbaand-tsx" */),
  "component---src-pages-driftsinformation-index-tsx": () => import("./../../../src/pages/driftsinformation/index.tsx" /* webpackChunkName: "component---src-pages-driftsinformation-index-tsx" */),
  "component---src-pages-driftsinformation-telefoni-tsx": () => import("./../../../src/pages/driftsinformation/telefoni.tsx" /* webpackChunkName: "component---src-pages-driftsinformation-telefoni-tsx" */),
  "component---src-pages-hastighedstest-index-tsx": () => import("./../../../src/pages/hastighedstest/index.tsx" /* webpackChunkName: "component---src-pages-hastighedstest-index-tsx" */),
  "component---src-pages-indeksregulering-index-tsx": () => import("./../../../src/pages/indeksregulering/index.tsx" /* webpackChunkName: "component---src-pages-indeksregulering-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-70-nr-svarsted-index-tsx": () => import("./../../../src/pages/kontakt/70-nr-svarsted/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-70-nr-svarsted-index-tsx" */),
  "component---src-pages-kontakt-indbetaling-error-tsx": () => import("./../../../src/pages/kontakt/indbetaling/error.tsx" /* webpackChunkName: "component---src-pages-kontakt-indbetaling-error-tsx" */),
  "component---src-pages-kontakt-indbetaling-index-tsx": () => import("./../../../src/pages/kontakt/indbetaling/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-indbetaling-index-tsx" */),
  "component---src-pages-kontakt-indbetaling-success-tsx": () => import("./../../../src/pages/kontakt/indbetaling/success.tsx" /* webpackChunkName: "component---src-pages-kontakt-indbetaling-success-tsx" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-kontakt-simkortaktivering-index-tsx": () => import("./../../../src/pages/kontakt/simkortaktivering/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-simkortaktivering-index-tsx" */),
  "component---src-pages-kontakt-udbetaling-tsx": () => import("./../../../src/pages/kontakt/udbetaling/[...].tsx" /* webpackChunkName: "component---src-pages-kontakt-udbetaling-tsx" */),
  "component---src-pages-kundeservice-all-pages-tsx": () => import("./../../../src/pages/kundeservice/all-pages.tsx" /* webpackChunkName: "component---src-pages-kundeservice-all-pages-tsx" */),
  "component---src-pages-kundeservice-search-results-index-tsx": () => import("./../../../src/pages/kundeservice/search-results/index.tsx" /* webpackChunkName: "component---src-pages-kundeservice-search-results-index-tsx" */),
  "component---src-pages-nyhedsbrev-afmeld-afmeldt-tsx": () => import("./../../../src/pages/nyhedsbrev/afmeld/afmeldt.tsx" /* webpackChunkName: "component---src-pages-nyhedsbrev-afmeld-afmeldt-tsx" */),
  "component---src-pages-nyhedsbrev-afmeld-index-tsx": () => import("./../../../src/pages/nyhedsbrev/afmeld/index.tsx" /* webpackChunkName: "component---src-pages-nyhedsbrev-afmeld-index-tsx" */),
  "component---src-pages-search-results-index-tsx": () => import("./../../../src/pages/search-results/index.tsx" /* webpackChunkName: "component---src-pages-search-results-index-tsx" */),
  "component---src-pages-sikkerhedsprofilen-alm-brand-tsx": () => import("./../../../src/pages/sikkerhedsprofilen-alm-brand/[...].tsx" /* webpackChunkName: "component---src-pages-sikkerhedsprofilen-alm-brand-tsx" */),
  "component---src-templates-article-article-tsx": () => import("./../../../src/templates/Article/Article.tsx" /* webpackChunkName: "component---src-templates-article-article-tsx" */),
  "component---src-templates-basic-page-basic-page-tsx": () => import("./../../../src/templates/BasicPage/BasicPage.tsx" /* webpackChunkName: "component---src-templates-basic-page-basic-page-tsx" */),
  "component---src-templates-basic-page-category-page-tsx": () => import("./../../../src/templates/BasicPage/CategoryPage.tsx" /* webpackChunkName: "component---src-templates-basic-page-category-page-tsx" */),
  "component---src-templates-basic-page-help-article-tsx": () => import("./../../../src/templates/BasicPage/HelpArticle.tsx" /* webpackChunkName: "component---src-templates-basic-page-help-article-tsx" */),
  "component---src-templates-contact-page-contact-page-category-tsx": () => import("./../../../src/templates/ContactPage/ContactPageCategory.tsx" /* webpackChunkName: "component---src-templates-contact-page-contact-page-category-tsx" */),
  "component---src-templates-contact-page-contact-page-tsx": () => import("./../../../src/templates/ContactPage/ContactPage.tsx" /* webpackChunkName: "component---src-templates-contact-page-contact-page-tsx" */),
  "component---src-templates-product-page-product-page-tsx": () => import("./../../../src/templates/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-product-page-tsx" */)
}

